import React from "react"

import { InternalTemplate } from "../components/InternalTemplate"
import { parseBlockContent } from "utils/customBlocks"

const InternalPage = ({ pageContext }) => {
  const {
    title,
    content,
    categories,
    textOptions,
    header,
    relatedContent,
    featuredImage,
  } = pageContext

  const customContent = content ? parseBlockContent(content) : null
  const tags = categories.nodes.map(cat => cat.name)

  const author = "Jonathan Sagar and Mark Di Somma"

  const date = "28 Jan 2020"
  return (
    <InternalTemplate
      tags={tags}
      author={author}
      date={date}
      content={customContent}
      textOptions={textOptions}
      internal
      title={title}
      relatedContent={relatedContent}
      featuredImage={featuredImage}
      headerImg={header ? header.headerImage : false}
    />
  )
}

export default InternalPage
